import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import config from '../../app/actions/config'
import totalDrivers from "../../assets/svg/profile.png";
import ystar from "../../assets/svg/ystar.svg";
import dropoff from "../../assets/svg/dropoff.svg";
import pickup from "../../assets/svg/pickup.svg";

import "react-datepicker/dist/react-datepicker.css";
import useAxiosFile from "../actions/useaxios";
import { isEmpty, sectomin } from "../../lib/common";


const DriverStatement = () => {
    const [tripRoute, setTrips] = useState({});
    const axios = useAxiosFile();

    const [loading2, setLoading2] = useState(false);

    const location = useLocation()
   
    useEffect(()=>{
        FetchData(location?.state?.state?._id)
    },[location?.state?.state?._id])

    const FetchData = async (data) => {
        let senddata = {
          status: "indi",
         _id:location?.state?.state?._id
        };
        //
        var resp = await axios.Trips(senddata);
       
        setTrips(resp?.data?.[0]);
      };
     

      const handlePayment = async(e)=>{
        // e.preventDefault();
        setLoading2(true);
        const data ={
            name: tripRoute?.driverid?.fullname,
            amount: Math.ceil(location?.state?.state?.driverpayment),
            number:tripRoute?.driverid?.mobile,
            MUID: "MUID" + Date.now(),
            transactionId: 'T' + Date.now(),
        }
    
        var triggerpayment = await axios.MakePayment(data)
       
        window.location.href=triggerpayment.data
        // axios.post('api/payment', {...data}).then(res => {  
        // setTimeout(() => {
        //     setLoading2(false);
        // }, 1500);
        // })
        // .catch(error => {
        //     setLoading2(false)
        //     console.error(error);
        // });   
    }



console.log(" tripRoute?.from", location?.state?.state)
    return (
        <div style={{ color: "black" }}>
            <div className="title_fixed">
                <div className="row">
                    <div className="col-9 col-sm-10">
                        <h4 className="card-title new_headings mt-3  mb-4 ">
                            Trip Details
                        </h4>
                    </div>
                    <div className="col-3 col-sm-2 mt-3 ">
                        <NavLink
                            className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
                            to={location?.state?.state?.from== "revenue"? "/revenue" : location?.state?.state?.from== "ridereport"?"/ride-report" :  location?.state?.state?.from== "timebasedreport"? "/report-statement"    :"/trip-route"}
                        >
                            Back
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-9 col-xl-8 col-md-8 mx-auto">
                    <div className="userdetails mt-5">
                                <h5 className="mb-5">Booking Number - {tripRoute?.bookingnumber}</h5>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 mt-3 mb-3">
                                <div className="d-flex">

                                    <div className="mr-3 position-relative">
                                        <img src={tripRoute?.driverid?.profile?
                                            `${config.ImG}/driver/${tripRoute?.driverid?._id}/${tripRoute?.driverid?.profile}`
                                            :totalDrivers} className="img-fluid imgicon" />
                                        <p className="mb-0 ptag"><img src={ystar} className="img-fluid ystar" /> {tripRoute?.driverid?.maxrate}</p>
                                    </div>
                                    <div>
                                        
                                        <h5 className="mb-0">{tripRoute?.driverid?.fullname}</h5>
                                        <p className="mb-0 mt-2">{tripRoute?.driverid?.vehiclenumber}</p>
                                        <p className="mb-0 mt-1">{tripRoute?.driverid?.mobile}</p>
                                        <p className="mb-0 mt-1">{tripRoute?.driverid?.delete == 1 ? "(Deleted Driver)":""}</p>

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 mt-3 mb-3">
                                <div className="d-flex">
                                    <div className="mr-3 position-relative">
                                        <img src={
                                            tripRoute?.userid?.profile?
                                            `${config.ImG}/user/${tripRoute?.userid?._id}/${tripRoute?.userid?.profile}`
                                            :totalDrivers} className="img-fluid imgicon" />
                                        <p className="mb-0 ptag"><img src={ystar} className="img-fluid ystar" /> {tripRoute?.driverid?.maxrate}</p>
                                    </div>
                                    <div>
                                    <h5 className="mb-0">{tripRoute?.userid?.fullname}</h5>
                                        <p className="mb-0 mt-2">Vehicle</p>
                                        <p className="mb-0 mt-1">{tripRoute?.userid?.mobile}</p>
                                        <p className="mb-0 mt-1">{tripRoute?.userid?.delete == 1 ? "(Deleted User)":""}</p>

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 mt-4 mb-2">
                                <h4><img src={pickup} className="img-fluid mr-2 mb-2" /> Pick Up</h4>
                                <p className="pick">{tripRoute?.location?.pickup}</p>
                            </div>
                            <div className="col-md-6 col-sm-6 mt-4 mb-2">
                                <h4><img src={dropoff} className="img-fluid mr-2 mb-2" /> Drop Off</h4>
                                <p className="pick">{tripRoute?.location?.dropoff}</p>
                            </div>
                            <div className="col-md-12 mt-2 mb-3">
                                <h4 className="mb-4">Trip Fare</h4>

                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>Price Per {tripRoute?.km}Km</td>
                                            <td>₹ {tripRoute?.TotalPrice?.kilometerprice??0}</td>
                                        </tr>
                                        <tr>
                                            <td>Price per {sectomin(tripRoute?.time)} Min</td>
                                            <td>₹ {tripRoute?.TotalPrice?.minuteprice??0}</td>
</tr>
                                     
                                        <tr>
                                            <td>Price Surge</td>
                                            <td>₹ {tripRoute?.vehicletype?.pricesurge}</td>

                                        </tr>
                                        <tr>
                                            <td>Insurance</td>
                                            <td>₹ {tripRoute?.vehicletype?.insurance}</td>

                                        </tr>
                                       {
                                       (!isEmpty(tripRoute?.TotalPrice?.tollprice))&&
                                       <tr>
                                            <td>Toll Price for {tripRoute.istoll} </td>
                                                                                       <td>₹ {tripRoute?.TotalPrice?.tollprice}</td>

                                        </tr>}
                                        <tr>
                                            <td>MCD Tax</td>
                                                                                       <td>₹ {tripRoute?.TotalPrice?.mcdtax}</td>

                                        </tr>
                                        <tr>
                                            <td>GST %</td>
                                                                                       <td>₹ {tripRoute?.TotalPrice?.gstprice}</td>

                                        </tr>
                                        <tr>
                                            <td>commission %</td>
                                                                                       <td> {tripRoute?.vehicletype?.commission} %</td>

                                        </tr>
                                        <tr>
                                            <td>commission in price</td>
                                                                                       <td> ₹{tripRoute?.TotalPrice?.commission} </td>

                                        </tr>

                                        <tr>
                                            <td>Peak Time Charge</td>
                                                                                       <td> ₹{tripRoute?.vehicletype?.peaktimecharge} </td>

                                        </tr>
                                        <tr>
                                            <td>Base price</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.baseprice?tripRoute?.vehicletype?.baseprice:0} </td>

                                        </tr>
                                        {/* <tr>
                                            <td>user cancellation time limit (in Minute)</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.price}</td>

                                        </tr>
                                        <tr>
                                            <td>user cancellation charges</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.price}</td>

                                        </tr>
                                        <tr>
                                            <td>waiting time limit (in Minutes)</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.price}</td>

                                        </tr>
                                        <tr>
                                            <td>waiting charges per minute</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.price}</td>

                                        </tr>
                                        <tr>
                                            <td>in route/transit waiting fee per minute</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.price}</td>

                                        </tr> */}
                                        <tr>
                                            <td>Others 1</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.others1}</td>

                                        </tr>
                                        <tr>
                                            <td>Others 2</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.others2}</td>

                                        </tr>
                                        <tr>
                                            <td>Others 3</td>
                                                                                       <td>₹ {tripRoute?.vehicletype?.others3}</td>

                                        </tr>

                                        <tr>
                                            <td><h4>Total </h4></td>
                                            <td><h4>₹ {tripRoute?.TotalPrice?.subtotal}</h4></td>
                                        </tr>
                                        <tr>
                                            <td><h4>Promo</h4></td>
                                            <td><h4>₹ 0</h4></td>
                                        </tr>
                                        <tr>
                                            <td><h4>Sub Total (subtotal-Promo)</h4></td>
                                            <td><h4>₹ {tripRoute?.TotalPrice?.subtotal}</h4></td>
                                        </tr>
                                        {
                                            location?.state?.from =="revenue" &&
                                            <tr>
                                            <td><h4>Driver Payment</h4></td>
                                            <td><h4>₹ {location?.state?.state?.driverpayment}</h4></td>
                                            {/* <td><button onClick={()=>handlePayment()}>Pay now</button></td> */}
                                        </tr>
                                        }
                                         {
                                            location?.state?.from =="revenue" &&
                                            <tr>
                                            <td><h4>Admin Earning</h4></td>
                                            <td><h4>₹ {location?.state?.state?.adminearning}</h4></td>
                                            {/* <td><button onClick={()=>handlePayment()}>Pay now</button></td> */}
                                        </tr>
                                        }
                                        <tr>
                                            <td><h4>TOTAL (including all tax)</h4></td>
                                            <td><h4>₹ {tripRoute?.TotalPrice?.grandtotal}</h4></td>
                                        </tr>
                                       

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default DriverStatement;
