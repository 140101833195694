import React, { useEffect, useMemo, useState } from "react";
import dotenv from "dotenv";

import Tab from "react-bootstrap/Tab";
import { Nav } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { NavLink } from "react-router-dom";
import GMap from "../../map/GMap";
import Autocomplete from "react-google-autocomplete";
import { isEmpty } from "../../lib/common";
import { socket } from "../actions/socket";

const CarAvailability = () => {
  /**lp */
  const [Active, SetActive] = useState({
    "Active Users": 0,
    "Avaiable Cars": 0,
    user: [],
    driver: [],
  });

  const [from, setfrom] = useState("user");
  const [isOpen, SetIsOpen] = useState("");
  const [Show, SetShow] = useState("");
  const [center, setcenter] = useState([]);

  useEffect(() => {
    socket.connect();
    socket.emit("getalldriveranduser");
    socket.on("getalldriveranduserbk", (data) => {
       console.log("datadatadatadata",data)
      //  SetActive({
      //   "Active Users": data?.user?data?.user?.length:Active["Avaiable Users"],
      //   "Avaiable Cars": data?.driver? data?.driver?.length:Active["Active Cars"],
      //   user: data?.user?data?.user:Active["user"],
      //   driver: data?.driver? data?.driver:Active["driver"],
      // });
      SetActive({
        "Active Users":data?.user?.length,
        "Avaiable Cars":  data?.driver?.length,
        user: data?.user,
        driver:  data?.driver,
      });
    });
   return()=>{
    socket.off("getalldriveranduserbk")
   }
  }, [socket]);

  const switchtab = (data) => {
    setfrom(data);
  };
  return (
    <div style={{ color: "black" }}>
      <div className="row">
        <div className="col-12">
          <h4 className="card-title new_headings mt-3  mb-4 ">
            Cab Availability and Scheduling
          </h4>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body paddingFull_zero">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="row flex-column-reverse flex-sm-row">
                  <div className="col-sm-7 col-xl-9">
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="user"
                          className="text-center green_clrr"
                          onClick={(e) => {
                            switchtab("user");
                          }}
                        >
                          <h2 className="h2_style">
                            {Active?.["Active Users"]}
                          </h2>
                          <p className="p_style">Active Users</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="position-relative">
                        <Nav.Link
                          eventKey="driver"
                          className="text-center  green_clrr "
                          onClick={(e) => {
                            switchtab("driver");
                          }}
                        >
                          <h2 className="h2_style">
                            {Active?.["Avaiable Cars"]}
                          </h2>
                          <p className="p_style">Available Cars</p>
                        </Nav.Link>
                      </Nav.Item>

                      <div className="small-polygon-logo position-absolute"></div>
                    </Nav>
                  </div>
                  <div className="col-sm-5 col-xl-3  d-flex align-items-center justify-content-end pr-4 mt-3 mb-3  pl-3">
                    <div className="search_input d-flex align-items-center map_input">
                      <FiSearch className="search_icon" />

                      <Autocomplete
                        apiKey={process?.env?.REACR_APP_G_API}
                        className="form-control"
                        onPlaceSelected={(place) => {
                          setcenter([
                            place?.geometry?.location.lat(),
                            place?.geometry.location.lng(),
                          ]);
                        }}
                        options={{
                          types: ["locality"],
                        }}
                      />
                    </div>
                  </div>
                </div>
                {console.log("Active?.[from]}",Active?.[from],from)}
                {
                  // mapshow &&
                  <div>
                    <GMap
                      from={from}
                      Alls={Active?.[from]}
                      isOpen={isOpen}
                      SetIsOpen={SetIsOpen}
                      center={center}
                      page={"availablity"}
                    />
                  </div>
                }

                {Show && (
                  <Tab.Content className="tab_cont">
                    <Tab.Pane eventKey="first">
                      <div className="map_sec">
                        <div className="position-absolute map_pointer">
                          <div className="map_pointer_wrapper">
                            <div className="map_hover_top_sec d-flex align-items-center justify-content-between">
                              {/* <p className="mb-0 top_p">
                              125298 R <span>(Junction booking)</span>
                            </p> */}
                              <MdOutlineCancel className="cancel_icon" />
                            </div>
                            <div className="middle_sec d-flex alig-items-center justify-content-between">
                              <p className="mb-0">
                                {Show?.fullname} (ID {Show?.driverid})
                              </p>
                              <p className="mb-0" style={{ color: "#76a488" }}>
                                {Show?.vechicletype?.vehiclemodel}
                              </p>
                            </div>
                            <div className="bot_sec">
                              <div className="d-flex align-items-center mb-2 ">
                                <img
                                  src={require("../../assets/images/youcab/pick.svg")}
                                  alt="pickup"
                                  className="img-fluid"
                                />
                                <p className="mb-0 ml-2">
                                  {Show?.currentLocation}
                                </p>
                              </div>
                              {/* <div className="d-flex align-items-center ">
                              <img
                                src={require("../../assets/images/youcab/drop.svg")}
                                alt="drop"
                                className="img-fluid"
                              />
                              <p className="mb-0 ml-2">Madurai,Airport</p>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <img src={carMap} alt="" className="img-fluid full_img" /> */}
                    </Tab.Pane>
                  </Tab.Content>
                )}
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarAvailability;
