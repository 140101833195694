import React, { useEffect, useState } from "react";
import {  BiChevronRight } from "react-icons/bi";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import { NavLink } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../table/table";
import useAxiosFile from "../actions/useaxios";
import { Link, useHistory } from "react-router-dom";



const Revenue = () => {
  const history = useHistory()
  const [pages, setpages] = useState(1);
const [load, setload] = useState(false);

  const [limit, setLimit] = useState(0);
  const [Count, setcount] = useState({
    day:0,
    week:0,
    month:0,
    year:0
  });
  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
   
    { key: 'bookingnumber', label: 'Ride ID', html: ''},
    { key: 'drivername', label: 'Driver Name', html: ''},
    { key: 'ridername', label: 'Rider Name', html: ''},
    { key: 'ridedate', label: 'Ride Date', html: ''},
  
    { key: 'grandtotal', label: 'Grand Total', html: '' },
    { key: 'driverpayment', label: 'Driver Payment', html: '' },
    { key: 'adminearning', label: 'Admin Earning', html: '' },
    { key: 'paymentmethod', label: 'Payment Type',  html:"" },
    { key: "_id", label: "View Invoice",  html: (cell, row, rowind, colind) => <div onClick={()=>history.push("/user-statement",{state:{...row,...{from:'revenue'}}})}>
    View
     
   </div>
 },
    
  ];

const Pagination = (limit, page) => {

  setpages(page);
  setLimit(limit);
};
  const axiosFile = useAxiosFile();

  const [listdriverdata, setListdriverdata] = useState([]);
  
  useEffect(() => {
    // if (pages && limit) {
      listDriverData("data");
    // }
  }, [pages,limit])
  
  const listDriverData = async (data) => {
    try {
        let senddata = {
          page: pages, limit: limit
        }
        var res = await axiosFile.revenue(senddata);
        setListdriverdata(res?.data);
        setcount({
          day:res.day,
          week:res.week,
          month:res.month,
          year:res.year
        })
setload(true)

       
    } catch (e) {
setload(true)
  
    }
  
  }
  


  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
              Revenue and Transaction Reports
            </h4>
          </div>
          <div className="col-3 col-sm-2 ">
            <NavLink
              className="text-left text-sm-right  d-flex align-items-center justify-content-end "
              to="/dashboard"
              style={{ color: "black", cursor: "pointer" }}
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row row_width mb-5 mt-5">
        <div className="col-sm-6 col-md-4 col-xl-3">
          <div className="card border_none">
            <div className="card-body card_padding card_earnign violet">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">Today Earning</p>
                  <p className="mb-0 earn_amount mt-1">₹ {Count?.day}</p>
                </div>
                <div className="earn_img">
                  <img
                    src={dailyEarnings}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
               <Link to={{pathname:"/report-statement",state:"day"}} state="day" className="no_link_a"> 
              <div className="report pads d-flex align-items-center justify-content-between">
               <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
               </Link>
            </div>
          </div>
        </div>
        <div className="mt-4 mt-sm-0 col-sm-6 col-md-4 col-xl-3">
          {" "}
          <div className="card border_none">
            <div className="card-body card_padding card_earnign red">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">This Week Earning</p>
                  <p className="mb-0 earn_amount mt-1">₹ {Count?.week}</p>
                </div>
                <div className="earn_img">
                  <img
                    src={dailyEarnings}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <Link to={{pathname:"/report-statement",state:"week"}} state="week" className="no_link_a"> 
              <div className="report pads d-flex align-items-center justify-content-between">
               <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
               </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-3 mt-4 mt-md-0">
          {" "}
          <div className="card border_none">
            <div className="card-body card_padding card_earnign blue">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">This Month Earning</p>
                  <p className="mb-0 earn_amount mt-1">₹ {Count?.month}</p>
                </div>
                <div className="earn_img">
                  <img
                    src={dailyEarnings}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <Link to={{pathname:"/report-statement",state:"month"}} state="month" className="no_link_a"> 
              <div className="report pads d-flex align-items-center justify-content-between">
               <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
               </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-3 mt-4 mt-xl-0">
          {" "}
          <div className="card border_none">
            <div className="card-body card_padding card_earnign green">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">This Year Earning</p>
                  <p className="mb-0 earn_amount mt-1">₹ {Count?.year}</p>
                </div>
                <div className="earn_img">
                  <img
                    src={dailyEarnings}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <Link to={{pathname:"/report-statement",state:"year"}} state="year" className="no_link_a"> 
              <div className="report pads d-flex align-items-center justify-content-between">
               <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
               </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
            <Table
load={load}

                          column={column}
                          data={listdriverdata}
                          Pagination={Pagination}
                          excel={true}
                          csv={true}
                          print={true}
                          search={true}
                          fileName={"Drivers"}
                        />
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Revenue;
